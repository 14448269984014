<template>
  <div class="page-wrap">
    <div class="header">
      <nav class="radioTime">
        <el-radio-group v-model="radioTime" @change="setTime(1)">
          <el-radio v-model="radioTime" label="Daily">Daily</el-radio>
          <el-radio v-model="radioTime" label="Weekly">Weekly</el-radio>
          <el-radio v-model="radioTime" label="Monthly">Monthly</el-radio>
          <el-radio v-model="radioTime" label="Annual">Annual</el-radio>
        </el-radio-group>
      </nav>
      <nav>
        <span class="datepicker">
          <el-date-picker
            ref="dataPicker"
            @change="setTime(2)"
            :picker-options="{ firstDayOfWeek: 1 }"
            :clearable="false"
            v-model="beginDate"
            :key="radioTime"
            :type="pickerType"
            :value-format="radioTime === 'Daily' ?'timestamp': ''"
            placeholder="select"
            size="mini"
          >
          </el-date-picker>
        </span>
        <span class="UTC">UTC - 4:00</span>
      </nav>
      <nav>
        <span style="font-size: 12px; margin-right: 10px"> User:</span>
        <el-select v-model="params.userId" placeholder="" @change="getList" @focus="getUserList" size="mini" clearable="">
          <el-option
            v-for="item in userList"
            :key="item.userId"
            :label="item.name"
            :value="item.userId"
          ></el-option>
        </el-select>
      </nav>
      <i class="download el-icon-download" @click="download"></i>
    </div>
    <div class="main">
      <el-table :data="list" size="mini" @sort-change="sortList" show-summary sum-text="Total" :summary-method="getSummaries">
        <el-table-column label="User" prop="name" class-name="list-colum"></el-table-column>
        <el-table-column label="Push" align="center" class-name="list-colum">
          <el-table-column label="Match" sortable="custom" :sort-by="['push', 'Standard_Match', 'count']">
            <template v-slot="{ row }">
              {{ row.push.Standard_Match && row.push.Standard_Match.count | formatNum }}
            </template>
          </el-table-column>
          <el-table-column label="Accuracy" sortable="custom" :sort-by="['push', 'Standard_Match', 'accuracy']">
            <template v-slot="{ row }">
              {{ row.push.Standard_Match && row.push.Standard_Match.accuracy }}
            </template>
          </el-table-column>
          <el-table-column
            label="Third Match"
            class-name="list-colum"
            sortable="custom"
            :sort-by="['push', 'Third_Match', 'count']"
          >
            <template v-slot="{ row }">
              {{ row.push.Third_Match && row.push.Third_Match.count | formatNum }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="Create Standard Data" align="center" class-name="list-colum">
          <el-table-column label="Region" sortable="custom"  :sort-by="['create', 'Standard_Region', 'count']">
            <template v-slot="{ row }">
              {{ row.create.Standard_Region && row.create.Standard_Region.count | formatNum }}
            </template>
          </el-table-column>
          <el-table-column
            label="Tournament"
            sortable="custom"
            :sort-by="['create', 'Standard_Tournament', 'count']"
          >
          <template v-slot="{ row }">
              {{ row.create.Standard_Tournament && row.create.Standard_Tournament.count | formatNum  }}
            </template></el-table-column>
          <el-table-column label="Team" sortable="custom" :sort-by="['create', 'Standard_Team', 'count']" class-name="list-colum">
            <template v-slot="{ row }">
              {{ row.create.Standard_Team && row.create.Standard_Team.count | formatNum }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="Mapping" align="center" class-name="list-colum">
          <el-table-column
            label="Tournament"
            sortable="custom"
            :sort-by="['mapping', 'Third_Tournament', 'count']"
          >
           <template v-slot="{ row }">
              {{ row.mapping.Third_Tournament && row.mapping.Third_Tournament.count | formatNum }}
            </template>
          </el-table-column>
          <el-table-column label="Accuracy" sortable="custom" :sort-by="['mapping', 'Third_Tournament', 'accuracy']">
            <template v-slot="{ row }">
              {{ row.mapping.Third_Tournament && row.mapping.Third_Tournament.accuracy }}
            </template>
          </el-table-column>
          <el-table-column label="Team" sortable="custom" :sort-by="['mapping', 'Third_Team', 'count']">
            <template v-slot="{ row }">
              {{ row.mapping.Third_Team && row.mapping.Third_Team.count | formatNum }}
            </template>
          </el-table-column>
          <el-table-column label="Accuracy" sortable="custom" :sort-by="['mapping', 'Third_Team', 'accuracy']">
            <template v-slot="{ row }">
              {{ row.mapping.Third_Team && row.mapping.Third_Team.accuracy }}
            </template>
          </el-table-column>
          <el-table-column
            label="Mapping Match"
            sortable="custom"
            :sort-by="['mapping', 'Third_Match', 'count']"
          >
           <template v-slot="{ row }">
              {{ row.mapping.Third_Match && row.mapping.Third_Match.count | formatNum }}
            </template>
          </el-table-column>
          <el-table-column
            label="Create Match"
            sortable="custom"
            class-name="list-colum"
            :sort-by="['mapping', 'Standard_Match', 'count']"
          >
           <template v-slot="{ row }">
              {{ row.mapping.Standard_Match && row.mapping.Standard_Match.count | formatNum }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="Internationalization" :sort-by="['internationalization', 'Language', 'count']" sortable="custom">
          <template v-slot="{ row }">
              {{ row.internationalization.Language && row.internationalization.Language.count | formatNum }}
            </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getTimeCode, formatNum} from '@/utils'
import { statistics as getData, userList } from '@/service'
import moment from 'moment'
import qee from 'qf-export-excel'
const excelTitle = [
  {
    title: 'User',
    key: 'name'
  },
  {
    title: 'Push Match',
    key: 'push_Standard_Match'
  },
  {
    title: 'Push Match Accuracy',
    key: 'push_Standard_Match_accuracy'
  },
  {
    title: 'Push Third Match',
    key: 'push_Third_Match'
  },
  {
    title: 'Create Standard Region',
    key: 'create_Standard_Region'
  },
  {
    title: 'Create Standard Tournament',
    key: 'create_Standard_Tournament'
  },
  {
    title: 'Create Standard Team',
    key: 'create_Standard_Team'
  },
  {
    title: 'Mapping Tournament',
    key: 'mapping_Third_Tournament'
  },
  {
    title: 'Mapping Tournament Accuracy',
    key: 'mapping_Third_Tournament_accuracy'
  },
  {
    title: 'Mapping Team',
    key: 'mapping_Third_Team'
  },
  {
    title: 'Mapping Team Accuracy',
    key: 'mapping_Third_Team_accuracy'
  },
  {
    title: 'Mapping Match',
    key: 'mapping_Third_Match'
  },
  {
    title: 'Mapping Create Match',
    key: 'mapping_Standard_Match'
  },
]
const accuracyList = ['Third_Team','Third_Tournament', 'Standard_Match']
export default {
  data() {
    return {
      pickerType: 'date',
      radioTime: 'Daily',
      beginDate: '',
      params: {
        startTime: '',
        endTime: ''
      },
      userList: [],
      list: []
    }
  },
  methods: {
    getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = 'Total';
            return;
          }
          const [key1,key2, key3] = column.sortBy
          const values = data.map(item => Number(item[key1][key2] && item[key1][key2][key3]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            if(column.label !== 'Accuracy') {
              sums[index] = formatNum(sums[index])
            }
            console.log(column)
          } else {
            sums[index] = '';
          }
        });

        return sums;
    },
    getUserList () {
      userList().then(res => {
        if(res.code === 0) {
          this.userList = res.data
        }
      })
    },
    download() {
      const total = {name: 'Total'}
      const list = this.list.map(item => {
        const data = {name: item.name};
        ['create', 'mapping', 'push'].map(key => {
          const info = item[key]
          for(let n in info) {
            data[`${key}_${n}`] = info[n].count
            if(total[`${key}_${n}`]) {
              total[`${key}_${n}`] = total[`${key}_${n}`] + Number(info[n].count) || 0
            } else {
              total[`${key}_${n}`] = Number(info[n].count) || 0
            }
            if(accuracyList.includes(n)) {
              data[`${key}_${n}_accuracy`] = info[n].accuracy
            }
          }
        })
        return data
      })
      list.push(total)
      qee(excelTitle,list, `Workload-${this.radioTime}-${this.$refs.dataPicker.displayValue}`)
    },
    sortList({ column, prop, order }) {
      if(order) {
        console.log(order)
        const isAsc = order === 'ascending' ? 1 : -1
        const [key1, key2, val] = column.sortBy || []
        this.list.sort((a, b) => {
          if(key2 in a[key1] && key2 in b[key1]) {
            return parseInt(a[key1][key2][val]) * isAsc - parseInt(b[key1][key2][val]) * isAsc
          }
          return   a[key1][key2]? 1 * isAsc: b[key1][key2]? -1* isAsc: 1
        })
      }
    },
    getList() {
      getData(this.params).then((res) => {
        const list = {}
        if (res.code === 0) {
          const data = res.data
          for (let key in data) {
            if (Array.isArray(data[key])) {
              data[key].map((item) => {
                if(accuracyList.includes(item.dataType)) {
                  item.accuracy = +(+item.accuracy * 100).toFixed(2) + '%'
                }
                if (item.uid in list) {
                  if (key in list[item.uid]) {
                    list[item.uid][key][item.dataType] = item
                  } else {
                    list[item.uid][key] = {
                      [item.dataType]: item
                    }
                  }
                } else {
                  list[item.uid] = {
                    create: {},
                    mapping: {},
                    internationalization: {},
                    push: {},
                    uid: item.uid,
                    name: item.uname
                  }
                  list[item.uid][key][item.dataType] = item
                }
              })
            }
          }
        }
        this.list = Object.values(list)
      })
    },
    setTime(flag) {
      if (this.radioTime === 'Daily') {
        this.pickerType = 'date'
        if (flag == 1) {
          this.beginDate = getTimeCode('Daily')[0]
        }
        this.params.startTime = this.beginDate
        this.params.endTime = this.beginDate + 24 * 60 * 60 * 1000
      } else if (this.radioTime === 'Weekly') {
        this.pickerType = 'week'
        console.log(getTimeCode('Weekly'))
        if (flag == 1) {
          this.beginDate = getTimeCode('Weekly')[0] + 24 * 60 * 60 * 1000
        }
        this.params.startTime = +new Date(
          moment(this.beginDate).weekday(1).format('YYYY-MM-DD') + ' 00:00:00'
        )
        this.params.endTime = +new Date(
          moment(this.beginDate).weekday(7).format('YYYY-MM-DD') + ' 23:59:59'
        )
      } else if (this.radioTime === 'Monthly') {
        this.pickerType = 'month'
        if (flag == 1) {
          this.beginDate = getTimeCode('Monthly')[0]
        }
        this.params.startTime = +new Date(
          moment(this.beginDate).startOf('month').format('YYYY-MM-DD') +
            ' 00:00:00'
        )
        this.params.endTime = +new Date(
          moment(this.beginDate).endOf('month').format('YYYY-MM-DD') +
            ' 23:59:59'
        )
      } else if (this.radioTime === 'Annual') {
        this.pickerType = 'year'
        if (flag == 1) {
          this.beginDate = getTimeCode('year')[0]
        }
        this.params.startTime = +new Date(
          moment(this.beginDate).startOf('year').format('YYYY-MM-DD') +
            ' 00:00:00'
        )
        this.params.endTime = +new Date(
          moment(this.beginDate).endOf('year').format('YYYY-MM-DD') +
            ' 23:59:59'
        )
      }
      this.getList()
    }
  },
  mounted() {
    this.setTime(1)
  }
}
</script>

<style lang="less" scoped>
/deep/ .list-colum{
  border-right-color: #ccc !important;
}
.radioTime {
  /deep/ .el-radio {
    margin-right: 10px;
  }
  /deep/ .el-radio__label {
    font-size: 13px;
    color: #666666;
  }
  // /deep/ .el-radio__input.is-checked .el-radio__inner {
  //   border-color: #00a791;
  //   background: #00a791;
  // }
  // /deep/ .el-radio.is-checked {
  //   .el-radio__label {
  //     color: #00a791;
  //   }
  // }
}
.UTC {
  color: #666666;
  font-size: 12px;
  margin-left: 10px;
  margin-right: 22px;
}
.header {
  display: flex;
  align-items: center;
  position: relative;
  .download {
    position: absolute;
    right: 10px;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
  }
}
.main {
  margin-top: 10px;
}
</style>
